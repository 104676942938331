.constellation-container {
  position: relative;
}
.constellation-container:hover .history-overlay {
  display: block;
}
.history-overlay {
  z-index: 1;
  display: none;
  position:absolute;
	top:0;
	left:100%;
	padding:0
}
.history-overlay .node-event-frame-container {
  width: 260px;
}

.constellation-btns .btn {
  display: inline-block;
  margin-left: 10px;
}

.alarm-overlay {
  color: #fc0000;
  position: absolute;
  top: 5px;
  right: 13px;
  font-size: 15px;
}

.action-overlay {
  color: #00e600;
  position: absolute;
  top: 5px;
  right: 13px;
  font-size: 18px;
}

.alarm-text-overlay {
  display: none;
  float: right;
  position: absolute;
  top: 1px;
  right: 1px;
  background: white;
  color: black;
  padding: 3px;
  opacity: .8;
  font-Size: 14px;
}

.alert-container:hover .alarm-overlay {
  display: none;
}

.alert-container:hover .alarm-text-overlay {
  display: block;
  cursor: default;
  z-index: 1;
}

.status-overlay {
  width: 100%;
  text-align: center;
  background: #fc0000;
  opacity:0.5;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 15px;
}

.battery-overlay {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 10px;
  width: 15px;
}

/*start past events*/
.past-event-alarm-overlay {
  float: right;
  margin-top: -128px;
  margin-right: 5px;
  position: relative;
  color: #fc0000;
}

.past-alert-container:hover .past-event-alarm-overlay {
  display: none;
}
.past-alert-container:hover .past-event-alarm-text {
  display: block;
  cursor: default;
}

.past-event-alarm-text {
  display: none;
  float: right;
  margin-top: -131px;
  margin-right: 0px;
  position: relative;
  background: white;
  color: black;
  opacity: .8;
  padding: 3px;
  font-size: 14px;
}

.past-event-action-overlay {
  float: right;
  margin-top: -128px;
  margin-right: 5px;
  position: relative;
  color: #00e600;
}

.modal-button {
  float: right;
  margin-top: -20px;
  position: relative;
  opacity: 0.55;
  border: none;
}

.modal-button:hover {
  opacity: 0.80;
  cursor: pointer;
}
