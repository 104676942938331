.header {
  box-sizing: border-box;
  /* align-self: center; */
  /* height: 100%; */
  padding-top: 25px;
  padding-bottom: 20px;
  display: block;
  background-color: rgb(247,247,247);
  min-width: 815px;
}


.locix-logo{
  width: 30px;
  height: 30px;
}

.locix-branding{
  display: inline-block;
  margin-left: 20px;
  position: relative;
}

.search{
  float: right;
  display: inline-block;
  /* padding-top: 5px; */
}

.search-bar{
  font-weight: 300;
  font-size: 13px;
  border-radius: 20px;
  border: none;
  padding: 5px 5px 5px 38px;
  width: 400px;
  height: 30px;
  -webkit-box-shadow: 2px 2px 2px rgb(225,225,225);
          box-shadow: 2px 2px 2px rgb(225,225,225);
  position: relative;
  background: url("../media/SearchIcon.svg") no-repeat;
  background-position: 1em;
  background-size: 15px;
  background-color: white;
}

.search-bar:focus{
	outline: none;
  border: 1px solid rgb(0, 211, 252);
}

/* for search-selector */

.search-selector {
  padding-left: 20px;
  cursor: pointer;
}

.search-selector .drop-down {
  border: 1px solid rgb(0, 211, 252);
  width: 410px;
  height: auto;
  position: relative;
  margin: 0;
  border-radius: 5px;
  background: white;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding-inline-start: 0px;
}

.search-selector .drop-down li {
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
  white-space:nowrap;
  list-style-type: none;
}

.search-selector .drop-down a {
  text-decoration: none;
  color: #777;
}

.search-selector .drop-down li:not(:first-child) {
  border-top: 1px dotted #f6f6f6; /* light grey */
}

.search-selector .drop-down li:hover {
  color: black;
  background: #e6e6e6; /* another shade of grey */
}

.search-selector .drop-down li.selected {
  color: rgba(0, 0, 0, .6);
  background: rgba(45, 188, 225, 0.15); /* aqua */
  border-radius: 5px 5px 0 0;
  cursor: default;
}

/*end search-selector*/

.site-selector {
  float: right;
  color: #777;
  display: inline-block;
  padding-left: 30px;
  padding-right: 35px;
  padding-top: 8px;
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.site-selector .user-name {
  text-transform: uppercase;
  background: url("../media/Dropdown.svg") no-repeat;
  background-position: right;
  padding-right: 20px;
}


.site-selector .drop-down {
  position: absolute;
  right: 0;
  margin: 0;
  margin-top: 15 px;
  padding: 0;
  border-radius: 5px;
  background: white;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.site-selector .drop-down li {
  text-transform: uppercase;
  white-space:nowrap;
  list-style-type: none;
  padding: 15px 17px;
}

.site-selector .drop-down a {
  padding: 5px 5px;
  text-decoration: none;
  color: #777;
}

.site-selector .drop-down li:not(:first-child) {
  border-top: 1px dotted #f6f6f6; /* light grey */
}

.site-selector .drop-down li:hover {
  color: black;
  background: #e6e6e6; /* another shade of grey */
}

.site-selector .drop-down li.selected {
  color: rgba(0, 0, 0, .6);
  background: rgba(45, 188, 225, 0.15); /* aqua */
  border-radius: 5px 5px 0 0;
  cursor: default;
}

.site-selector .drop-down input {
  margin: 10px 10px 10px 10px;
  padding: 5px 5px 5px 5px;
  font-family: sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.logout {
  border-bottom: 1px solid #f6f6f6;
}
.logout i {
  margin-left: 0.25rem;
  margin-right: 0.5rem;
}
.logout .userName {
  display: inline-block;
  color: #1b5faa;
}

.logout .signout {
  float:right;
}

.site-selector .drop-down {
  height: 400px;
  overflow-y: scroll;
}
.site-selector .user-name {
}
.site-selector .dropdown-overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
