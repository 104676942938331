.constellation-btn {
  height: fit-content;
  display: block;
  color: black;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
  width: 4.8rem;
  border-radius: 1rem;
  border: none;
  padding: 0.3rem 0;
}
.constellation-btn:hover {
  cursor: pointer;
}

.constellation-btn.inactive {
  background-color: #dddddd;
}
.constellation-btn.inactive:hover {
  background-color: #eaeaea;
}

.constellation-btn.active {
  color: white;
  background-color: #fc0000;
}
.constellation-btn.active:hover {
  background-color: #fd6464;
}

/*
.constellation-btn {
  height: fit-content;
  display: block;
  color: black;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
  width: 4.8rem;
  border-radius: 1rem;
  border: none;
  padding: 0.3rem 0;
}
.constellation-btn:hover {
  cursor: pointer;
}

.constellation-btn.inactive {
  background-color: #dddddd;
}
.constellation-btn.inactive:hover {
  background-color: #eaeaea;
}

.constellation-btn.active {
  color: white;
  background-color: #fc0000;
}
.constellation-btn.active:hover {
  background-color: #fd6464;
}
*/
