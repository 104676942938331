.analytics-page {
  width: 1370px;
  height: 1240px;
    box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
    background-color: #ffffff;
    margin-top: 0px;
    margin-left: 10px;
  }

.asset-tracking-header {
  padding-top: 20px;
  padding-left: 20px;
  width: 178px;
  height: 27px;
  opacity: 0.8;
  font-family: Lato;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.row1 {
  margin: 20px;
}

.item1 {
  display: inline-block;
  vertical-align: top;
  width: 320px;
  height: 360px;
  overflow: hidden;
}

.item2 {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  width: 300px;
  height: 360px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.item3 {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  width: 320px;
  height: 360px;
  overflow: hidden;
}

.item4 {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
  width: 300px;
  height: 360px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.row2 {
  margin: 20px;
}

.item5 {
  display: inline-block;
  vertical-align: top;
  width: 650px;
  /*height: 282px;*/
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
}

.item5 h2{
  opacity: 0.8;
  font-family: Lato;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.item6 {
  margin-left: 30px;
  display: inline-block;
  vertical-align: top;
  width: 650px;
  /*height: 282px;*/
  height:auto;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
}

.item6 h2{
  opacity: 0.8;
  font-family: Lato;
  font-size: 20px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.chart-item{
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.row3 {
  margin-top: -15px;
  margin-left: 20px;
}

.item7 {
  vertical-align: top;
  width: 1330px;
  height: 400px;
  overflow: hidden;
}

.item7 img {
  width: 1300px;
  height: 450px;
  margin: 0px -1000px 0px 0px;
}

.item2-content {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.item2-content-header {
  display: inline-block;
  font-family: Lato;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0068b0;
}

.item2-status-icon {
  display: inline-block;
  color: red;
  font-size: 18px;
  margin: 1px;
  vertical-align: top;
}

.item2-status-occupied {
 display: inline-block;
 float:right;
 width: 90px;
 height: 20px;
 border-radius: 3px;
 background-color: #13a746;
}

.item2-status-empty {
 display: inline-block;
 float:right;
 width: 90px;
 height: 20px;
 border-radius: 3px;
 background-color: #77796c;;
}

/*this is the text that goes inside the status box*/
.item2-status-text {
  padding: 3px;
  /*font-family: CeraBasic;*/
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.item2-status-content-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 2px;
  margin-right: 2px;
}

.item2-status-key-value {
  display: inline-block;
  margin-bottom: 5px;
  width: 260px;
}

.item2-status-key {
  float: left;
  width: 163px;
  height: 16px;
  padding-right: 2px;
  opacity: 0.75;
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #565656;
}

.item2-status-value {
 display: inline-block;
 float: right;
 opacity: 0.75;
 font-family: Lato;
 font-size: 16px;
 font-weight: normal;
 font-style: normal;
 font-stretch: normal;
 line-height: 1.25;
 letter-spacing: normal;
 color: #000000;
}

.refresh-image-box {
  display:inline-block;
  margin-top: 22px;
  margin-left: 35px;
  width: 170px;
  height: 35px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #5194e2;
}

.refresh-image-text {
  display:inline-block;
  text-align:center;
  padding: 4px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.refresh-image-icon {
  display: inline-block;
  color: white;
  font-size: 14px;
  margin: 1px;
  vertical-align: center;
}

.crop-left img {
    width: 700px;
    height: 400px;
    /*margin: -75px 0 0 -100px;*/
}

.crop-right img {
  width: 700px;
  height: 400px;
  margin: 0px 0 0 -350px;
}

.truck-overlay {
  position: absolute;
  top: 450px;
  width: 320px;
  height: 62px;
  background-blend-mode: overlay;
  background-image: linear-gradient(to bottom, rgba(23, 23, 25, 0.4), rgba(23, 23, 25, 0.4));
}

.truck-overlay-text {
  margin-left: 15px;
  margin-top: 8px;
  height: 18px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.side-crop { max-width: 600px; overflow: hidden; }
