.settings {
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  align-items: stretch;
  margin-right: 20px;
  position: relative;
  background-color: white;
  font-family: Lato;
  color: #404040;
}

.settings .sidebar {
  padding-left: 5px;
  width: 240px;
  background-color: #f3f3f3;
}

.settings .sidebar .select {
  margin: 10px 0px 0px 0px;
  padding: 10px;
  font-size: 14px;
}

.settings .sidebar ul {
  list-style: none;
  padding-left: 0;
  margin-left: 15px;
}

.settings .sidebar a {
  color: #404040;
  display: block;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
}

.settings .sidebar a:hover {
  background-color: white;
  text-decoration: underline;
  color: #1c5faa;
}

.settings .sidebar a.active {
  background-color: white;
  color: #1b5faa;
}

.settings .sidebar .chevron {
  float: right;
  margin-right: 15px;
}

.settings .main {
  padding: 20px;
  flex-grow: 1;
}

/*.settings .main.site {
  text-align: center;
  padding-top: 50px;
}*/

.settings .main label {
  display: inline-block;
  width: 140px;
  font-size: 14px;
  text-align: right;
}

.settings .main input {
  border: solid 0.5px #979797;
  text-align: left;
  padding: 10px;
  margin-left: 150px;
  font-size: 16px;
  width: 350px;
}

.settings h2 {
  font-size: 22px;
  color: #404040;
}

.settings h3 {
  /*margin: 15px;*/
  margin: 10px 0px 0px 0px;
  font-size: 24px;
  color: #404040;
}

.settings h4 {
  margin: 0px;
  padding: 10px;
  font-size: 14px;
}

.settings #error {
  color: red;
}

/* setting table */
.public_fixedDataTable_header,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  font-size: 16px;
  color: #ffffff;
  background-image: none;
}

.main .public_fixedDataTable_header,
.main .public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: #1b5faa;
}

.public_fixedDataTable_main,
.public_fixedDataTable_header,
.public_fixedDataTable_hasBottomBorder,
.public_fixedDataTableCell_main {
  border: none;
  outline: none;
}

/*
.public_fixedDataTableCell_main {
  padding-left: 20px;
}
*/

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: white;
}

.settings .main.devices {
  color: rgba(0, 0, 0, .7);
}

.settings .public_fixedDataTable_main a {
  text-decoration: none;
  color: #6e8fe2;
  cursor: pointer;
}

.settings button {
  padding: none;
  margin: 0px 0px 25px 0px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  color: #1b5faa;
}

.settings button:hover {
  cursor: pointer;
}

.settings .button-container {
  text-align: right;
  max-width: 1075px;
  height: 45px;
}

.settings .button-container a {
  color: #1B5FAA;
  text-decoration: none;
  cursor: pointer;
}

.settings .line {
  width: 1075px;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #e8e8e8;
}

/*************** Edit Screen **********/
.settings .edit {
  margin-bottom: 25px;
}

.settings .edit h3 {
  margin-left: 16px;
  display: inline-block;
  color: #1b5faa;
  vertical-align: top;
}

.settings .edit .back {
  color: #1b5faa;
  vertical-align: top;
  margin-top: 16px;
}

.settings .edit .status {
  color: #1b5faa;
  margin-left: 15%;
  width: 300px;
}

.settings .edit .button-container {
  display: inline-block;
  width: 850px;
  height: 55px;

}

.settings .edit .button-container img {
  width: 22px;
  height: 11px;
}

.settings .edit .button-container .charging {
  vertical-align: bottom;
  width: 1.188rem;
  height:1.5rem;
}

.settings .edit .button-container .label {
  font-size: 14px;
  display: inline-block;
  text-transform: uppercase;
  margin-right: .625rem;
}

.settings .edit .button-container .label2 {
  font-size: 14px;
  display: inline-block;
  text-transform: capitalize;
  margin-right: 2.938rem;
}

.settings .edit .button-container .label3 {
  text-transform: none;
}

.settings .edit .restart {
  display: inline-block;
  vertical-align: top;
  margin-right: 1.25rem;
}

.settings .edit .calibrate {
  margin-right: 5rem;
}

.settings .edit .restart button {
  width: 100px;
  height: 34px;
  border-radius: 2px;
  border: solid 0.5px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  color: #505050;
}

.settings .edit .restart button:hover {
  color: #1b5faa;
}

.settings .edit .restart button:active {
  color: #024c9e;
}

.settings .edit .delete {
  display: inline-block;
}

.settings .edit .delete button {
  background: url('../../media/delete.png') left top no-repeat; background-size: 34px 34px;
  width: 34px;
  height: 34px;
  border: 0;
}

.settings .edit .delete button:hover {
  background: url('../../media/delete-hover.png') left top no-repeat; background-size: 34px 34px;
}

.settings .edit .delete button:active {
  background: url('../../media/delete-selected.png') left top no-repeat; background-size: 34px 34px;
}

.settings .edit .edit_container {
  margin-top: 25px;
  width: 1075px;
}

.settings .edit .label_container {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  height: 100%;
  font-size: 14px;
  color: #505050;
  text-transform: uppercase;
}

.settings .edit .label_container .label {
  margin-left: 25px;
  height: 50px;
  padding-top: 15px;
}

.settings .edit .label_container .label2 {
  margin-top: 6px;
  font-size: 12px;
  text-transform: capitalize;
}

.settings .edit .label_container .help {
  margin-left: .625rem;
  vertical-align: bottom;
  width: 1.125rem;
  height: 1.125rem;
}

.settings .edit .form_container {
  display: inline-block;
  vertical-align: top;
  width: 40%;
}

.settings .edit .form_container .fields {
  height: 35px;
  width: 345px;
  padding: 15px 0px 15px 5px;
}

.settings .edit .form_container .long_input input {
  margin: 0px 0px 0px 0px;
  width: 335px;
  height: 35px;
  padding: 0px 0px 0px 10px;
  outline: none;
  color: #404040;
  /*border-color: red;*/
}

.settings .edit .form_container .short_input input {
  margin: 0px 10px 0px 0px;
  width: 100px;
  height: 35px;
  padding: 0px 0px 0px 10px;
  outline: none;
  color: #404040;
  /*border-color: red;*/
}

.settings .edit .form_container .color {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 10px;
  width: 50px;
  height: 35px;
  border: 1px solid #404040;
}

.settings .edit .form_container .error {
    font-size: 11px;
    color: #b0021a;
    margin-left: 10px;
}

.settings .edit .form_container .help {
    font-size: 11px;
    margin-left: 10px;
}

.settings .edit .form_container .delete_field {
  float: right;
  color: #c41919;
  font-size: 24px;
}

.settings .edit .info_container {
  display: inline-block;
  width: 35%;
  font-size: 14px;
  text-transform: uppercase;
  color: #7b7b7b;
}

.settings .edit .info_container .sysid {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  text-transform: none;
}

.settings .edit .info_container .field_prop {
  display: inline-block;
  font-weight: bold;
  color: #202020;
  padding-right: 5px;
}


.settings .edit .info_container .field_value {
  display: inline-block;
  font-weight: normal;
  padding-left: 5px;
  color: #505050;
}

.settings .edit button {
  width: 130px;
  height: 38px;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}

.settings .edit .cancel {
  margin: 125px 0px 0px 350px;
  color: #000000;
  border: solid 0.5px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
}

.settings .edit .save  {
  margin: 0px 0px 0px 100px;
  color: #1c5faa;
  background-color: #1c5faa;
  color: #ffffff;
}

.settings .edit #save button:hover {
  background-color: #1b5faa;
}

.settings .edit #save button:active {
  background-color: #164c88;
}

.settings .edit #button_group button {
  width: auto;
  height: 38px;
  border: solid 0.5px #979797;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #404040;
  background-color: white;
}

.settings .edit #button_group button:hover {
  background-color: #1b5faa;
  color: #ffffff;
}

.settings .edit #button_group button:active {
  background-color: #164c88;
  color: #ffffff;
}

.settings .edit #button_group .icon {
  margin: 0px 5px 0px 5px;
}

.settings .edit #long_button button {
  width: 100%;
  background-color: white;
  border: solid 0.5px #1c5faa
}

.settings .edit #long_button button:hover {
  background-color: #1b5faa;
  color: #ffffff;
}

.settings .edit #long_button button:active {
  background-color: #164c88;
  color: #ffffff;
}

.settings .edit #range_slider .rangeslider-horizontal {
  top: -15px;
  border-radius: 0;
}

.settings .edit #range_slider .rangeslider-horizontal .rangeslider__fill {
  background-color: #c3c3c3;
}

.settings .edit #range_slider .rangeslider-horizontal .rangeslider__handle {
  width: 15px;
  height: 15px;
  border-radius: 0;
  border: 0;
  top: 50%;
  background-color: #1c5faa;
  transform: translate3d(-50%, -50%, 0);
}

.settings .edit #range_slider .rangeslider-horizontal .rangeslider__handle:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0px;
  left: 0px;
  border-radius: 0;
  background-color: #1c5faa;
}

.settings .edit .trash {
  font-size: 24px;
  color: #c41919;
}

.settings .edit .toggle-off {
  font-size: 22px;
  color: #27C649;
}

.settings .edit .toggle-on {
  font-size: 22px;
  color: #C2C2C2;
}

/*
.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: #7cb342;
  border-radius: 10px;
  top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: ' ';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: #dadada;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate3d(-50%, 0, 0);
}
*/

/*lps setting */
.settings .lps {
  /*width: 1075px;*/
  width: auto;
  height: 750px;
  margin-top: 20px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f7f7f7;
  color: #404040;
}

.settings .lps_menu {
  float: left;
  width: 260px;
  height: 100%;
  overflow: auto;
}

.settings .lps_selection {
  width: auto;
  height: 100%;
  border: solid 1px #979797;
  background-color: #eeeeee;
}

.settings .lps_header {
  margin: 20px 0px 20px 15px;
  font-size: 20px;
}

.settings .lps_line {
  margin-left: 20px;
  width: 85%;
  border: solid 1px #e8e8e8;
}

.settings .lps_menu_item {
  font-size: 16px;
  margin: 15px 0px 15px 20px;
  cursor: default;
}

.settings .lps_menu_item_selected {
  font-size: 16px;
  margin: 15px 0px 15px 20px;
  cursor: default;
  background-color: white;
  color: #1b5faa;
}

.settings .chevron {
  float: right;
  margin-right: 15px;
}

.settings .lps_menu_anchor {
  margin: 10px 0px 10px 25px;
}

.settings .edit {
  float: right;
  margin-right: 15px;
  height: 15px;
}

.settings .anchor_param {
  margin: 20px 0px 20px 25px;
  width: 85%;
  font-size: 14px;
  color: #505050;
}

.settings .lps_menu input {
  margin: 0px 5px 0px 0px;
  padding: 3px 5px 3px 5px;
  float: right;
  width: 100px;
  height: 15px;
  font-size: 14px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
}

.settings .lps_menu .color {
  display: inline-block;
  float: right;
  width: 25px;
  height: 23px;
}

.settings .lps_menu .short input {
  width: 75px;
}

.settings .lps_menu .floor_menu {
  float: right;
  margin-right: 10px;
}

.settings .lps_menu .floor_button {
  width: 30px;
  height: 30px;
  margin-top: -5px;
}

.settings .lps_menu button {
  margin: 0px 0px 0px 45%;
  font-size: 14px;
  width: 80px;
  height: 25px;
  color: #1b5faa;
  border: solid 0.5px #1b5faa;;
}

.settings .lps_menu button:hover {
  color: #024c9e;
  border: solid 0.5px #1c5faa;
}

.settings .lps_menu .delete button {
  position: relative;
  top: 5px;
  margin: 0px 0px 0px 5px ;
  background: url('../../media/delete.png') left top no-repeat; background-size: 26px 26px;
  width: 26px;
  height: 26px;
  border: none;
  vertical-align:bottom;
}

.settings .lps_menu .delete button:hover {
  background: url('../../media/delete-hover.png') left top no-repeat; background-size: 26px 26px;
}

.settings .lps_menu .delete button:active {
  background: url('../../media/delete-selected.png') left top no-repeat; background-size: 26px 26px;
}

.settings .lps_menu .add_text {
  font-size: 16px;
  color: #1b5faa;
  cursor: pointer;
}

.settings .user_management .select_option {
  width: 325px;
  display: inline-block;
}

.settings .user_management .delete {
  color: #c41919;
  font-size: 24px;
}
