#asset-map-node-list-container {
  display: inline-block;
  width: 19%;
  vertical-align: top;
  float: right;
}
#asset-map-node-list-container button {
  margin: 5px;
}

#asset-map-node-list-container label {
  display: block;
}

/* Old Button Style
#asset-map-node-list-container button {
    background-color: #01538d;
    border: 1px solid #fff;
    border-radius: 0.75em;
    color: white;
    font-size: 0.75em;
    display: inline-block;
}
*/

#asset-map-node-list-container button {
    background-color: #2476d6;
    border: 1px solid #fff;
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    display: inline-block;
    opacity: 1;
    outline: none;
}

#asset-map-node-list-container button:hover {
    opacity: .85
}

#asset-map-node-list-container button:active {
    opacity: .5
}

#asset-map-node-list-container button:disabled {
    background-color: #D3D3D3;
    color: #A9A9A9;
}

#asset-map-node-list-container button.node-not-tracking {
   /*float: right;*/
}

#asset-map-node-list-container button.node-tracking {
  opacity: .85;
   /*float: right;*/
}

#asset-map-node-list-container button.locate {
   /*float: left;*/
}

#asset-map-node-list-container ul li div {
   display: inline-block;
   width: 100%;
}

#asset-map-node-list-container ul {
  padding-left: 0px;
  margin-top: 0px;
}

#asset-map-node-list-container li {
  list-style: none;
  margin-bottom: 10px;
  padding: 10px;
}

.clear-selection button {
  outline: none;
  float: right;
  background-color: #2476d6;
  border: 1px solid #fff;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  display: inline-block;
  opacity: 1;
}

.clear-selection button:hover {
  opacity: .85;
}

#asset-map-node-list-container li.node-selected {
   background-color: #0068b0;
   color: white;
}

#asset-map-node-list-container li.node-not-selected {
   border: 1px solid white;
   background-color: white;
   color: black;
}
