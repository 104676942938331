.dashboard-content.camera-detail-page {
  /* width: 1205px; */
  background: #fff;
  box-shadow: 1px 1px 1px 1px #e1e1e1;
  -webkit-box-shadow: 1px 1px 1px 1px #e1e1e1;
  margin: 0 12px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  align-items: center;
}

.camera-detail-page .camera-title{
  font-weight: 300;
  color: rgba(0,0,0,.7);
  margin: 0;
  padding-left: 30px;
  padding-top: 30px;
  text-transform: uppercase;
  font-size: x-large;
}

.camera-detail-link {
  font-weight: 100;
  color: blue;
  text-transform: uppercase;
  float: right;
  padding-right: 10%;
  font-size: large;
}

.camera-detail-icon {
  float: right;
  padding-right: 2%;
  display: inline-block;
}

.camera-detail-page .activity-chart {
  margin-left: 20px;
  box-shadow: none;
  width: 640px;
  display: inline-block;
}

.camera-detail-page .detail-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.camera-detail-page .detail-header .event-frame {
  /* 1280×720 */
  width: 498px;
  height: 280px;
}

.camera-detail-page .detail-header .node-overlay .btn {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.camera-detail-page .recent-events .node-overlay .btn {
  display: none;
}
.camera-detail-page .recent-events .node-overlay:hover {
  cursor: default;
}
