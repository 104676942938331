.media-btn {
  border-radius: 1rem;
  border: 1px solid #ccc;
  padding: .3rem 0.9rem;
  font-size: 0.7rem;
  margin-left: 0.2rem;
}
.media-btn.checked {
  color: white;
  background-color: #6e8fe2;
  border: none;
}
.media-btn:hover {
  cursor: pointer;
}
.media-btn i {
  margin-right: 0.2rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
