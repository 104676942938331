.App .content-row {
  background: #f7f7f7;
}
.App .dashboard-content {
  /* max-width: 1229px; */
  padding: 0 12px;
  /* background: white; */
  /* margin: 0 12px; */
}
.App .constellation-event-frame-container,
.App .node-event-frame-container {
    /* width: 1205px; */
    background: #fff;
    box-shadow: 1px 1px 1px 1px #e1e1e1;
    -webkit-box-shadow: 1px 1px 1px 1px #e1e1e1;
}

.App .activity-chart {
  background: #fff;
  box-shadow: 1px 1px 1px 1px #e1e1e1;
  -webkit-box-shadow: 1px 1px 1px 1px #e1e1e1;
}

.App .node-event-frame-container {
  background: white;
  margin-bottom: 15px;
}
.App .event-frame {
  display: inline-block;
  position: relative;
  margin: 10px 0px 12px 14px;
  width: 225px;
  height: 127px;
}
.App .event-frame.no-media {
  background-color: #ddd;
}
.App .event-frame img,
.App .event-frame video {
  width: 100%;
  height: 100%;
}
.App .cameras-page .event-frame {
  width: 560px;
  height: 315px;
}

.App h2 {
  font-weight: 300;
  color: rgba(0,0,0,.7);
  margin: 0;
  padding-left: 15px;
  padding-top: 15px;
}
.App h1, .App h2 {
  text-transform: uppercase;
}

.App h3 {
    text-transform: uppercase;
    font-weight: 400;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 26px;
}
.constellation-name:hover {
  overflow: visible;
  white-space: normal;
}
.constellation-name {
    transition: height 1s ease 0.5s;
    height: auto;
    margin-left: 14px;
    font-size: 15px;
    color: #5295e4;
    display: inline-block;
    max-width: 170px;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}
.constellation-container {
  max-width: 240px;
  display: inline-block;
  margin-top: 10px;
}
.constellation-container .constellation-header {
  display: flex;
  justify-content: space-between;
}
.constellation-container .constellation-btn {
  display: inline-block;
}


.App select,
.home-page option {
    font-size: 14px;
	text-transform: uppercase;
    background: white;
}

.App select {
    color: #5295E4;
    border: 1px solid #5295E4;
	text-transform: uppercase;
    -webkit-box-shadow: none;
            box-shadow: none;
}

.home-page option {
    color: black;
}

.home-page option:checked {
    background: #f6f6f6;
}

.home-page option:hover {
    background: darkgrey;
}

.line-chart {
	padding-top: 40px;
}

.chart-range-selector-container {
    float: right;
    display: inline-block;
    margin-top: 20px;
    margin-right: 20px;
}
.activity-chart-title {
  display: inline-block;
  float: left;
  }

.activity-chart-legend {
  display: inline-block;
  float: right;
  }

.activity-chart-menu{
  display: inline-block;
  float: right;
}
.App .select-info-canvas {
  position: absolute;
  width: 100%;
  min-height: 700px;
  pointer-events: none;
  z-index: 2;
}
.asset-site-map {
  display: inline-block;
  width: 80%;
  margin-top: 20px;
  min-height: 700px;
  position: relative;
  overflow: hidden;
}
