.Container2D {
  display: flex;
  margin-left: 10px;
  background-color: #ffffff;
}

.Container2DRight{
  display:inline-block;
  vertical-align: top;
}

/*height: 60px;*/
.ControlContainer {
  margin-left: 10px;
  width: 100%;
  height: 55px;
  background-color:  #ffffff;
  box-shadow: -1px 0 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: solid 2px #1b5faa;
}

.ToggleController {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  box-shadow: 2px 0 7px 0 rgba(0, 0, 0, 0.15);
}

.NavFloorContainer {
  position: absolute;
  width: 32px;
  margin: 120px 0px 0px 1025px;
  z-index: 1;
}

.NavFloorContainer img {
  width: 32px;
  height: 32px;
  vertical-align: top;
}

.HeatmapContainer {
  display:inline-block;
  vertical-align:top;
}

.NavMap2D {
  margin-left: 10px;
  display:inline-block;
  background-color: #ffffff;
}

/*/*height: auto;*/
/*height: 1000px;*/
/*height: 1125px*/
/*height: 1235px;*/
.NavList2D {
  width: 300px;
  height: 100%;
  display:inline-block;
  box-shadow: -1px 0 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  overflow-y: auto;
}

/*  border-radius: 3px;
background-color: #0068b0;
color: #ffffff;
border-radius: 3px; */
.Button1 {
  display: inline-block;
  margin-top: 15px;
  margin-left: 40px;
  width: 100px;
  height: 24px;
  border: solid 1px #132c4f;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 6px;
  font-family: Lato;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

/*color: #132c4f;*/
.Button2 {
  display: inline-block;
  margin-top: 15px;
  width: 80px;
  height: 24px;
  border: solid 1px #132c4f;
  padding-top: 6px;
  font-family: Lato;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.MapType {
  display: inline-block;
  margin-top: 25px;
  margin-left: 40px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #505050;
  cursor: pointer;
}

.MapType2 {
  display: inline-block;
  margin-top: 25px;
  margin-left: 15px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #505050;
  cursor: pointer;
}

.MapTypeIcon {
  display: inline-block;
  margin-left: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.Duration {
  width: 85px;
  height: 19px;
  margin-left: 20px;
  margin-top: 20px;
  font-family: Lato;
  font-size: 16px;
  color: #00518a;
}

.BlueLine {
  width: auto;
  height: 1px;
  background-color: #1b5faa;
  border: solid 1px #1b5faa;
}

.SearchNodesBox {
  margin-left: 10px;
  margin-top: 10px;
  width: 230px;
  height: 28px;
  border-radius: 2px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 15px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #404040;
  padding-left: 30px;
}

.SearchNodesIcon {
  position: relative;
  margin-top: 15px;
  margin-left: -255px;
  color: #505050;
  width: 15px;
  height: 15px;
}

.NavList2D .Filter {
  display: inline-block;
  margin-left: 10px;
}

.NavList2D .Group {
  display: inline-block;
  font-size: 0.75rem;
  color: #505050;
  vertical-align: top;
  text-transform: uppercase;
  margin: 14px 0px 0px 12px;
}

.SelectGroupIcon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  object-fit: contain;
  margin-left: 5px;
  margin-top: 12px;
}

.SelectIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-left: 8px;
  margin-top: 12px;
}

.SelectIcon2 {
  display: inline-block;
  width: 30px;
  height: 30px;
  object-fit: contain;
  float: right;
  margin-right: 11px;
  margin-top: 12px;
  margin-bottom: 12x;
}

.SelectIconSmall {
  margin-left: 15px;
  vertical-align: middle;
  width: 15px;
  height: 15px;
}

.Line  {
  margin-top: 10px;
  width: auto;
  height: 1px;
  opacity: 0.68;
  background-color: #a0a0a0;
  border: solid 0.5px #a0a0a0;
}

.RadioContainer {
  margin-left: 21px;
  margin-top: 10px;
}

.Radio1 {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: solid 1px #a0a0a0;
}

.Radio1Text {
  display: inline-block;
  font-family: Lato;
  font-size: 15px;
  margin-left: 16px;
}

.AvailableDevices {
  display: inline-block;
  margin-top: 20px;
  margin-left: 15px;
  margin-bottom: 15px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.09;
  color: #505050;
}

.AnalyticsIconContainer {
  margin-top: 20px;
  margin-right: 20px;
  float: right;
}

.AnalyticsIcon {
  display: inline;
  margin-left: 15px;
  width: 20px;
  height: 17px;
  cursor: pointer;
}

.CheckBoxAll{
  display: inline-block;
  margin-left: 15px;
  vertical-align: bottom;
  width: 12px;
  height: 12px;
  border: solid 1px #193153;
}

.CheckBoxAllText {
  margin-top: 13px;
  vertical-align: top;
  display: inline-block;
  font-family: Lato;
  font-size: 16px;
  color: #404040;
}

.CheckBoxAllText2 {
  margin-left: 10px;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  color: #505050;
  text-transform: uppercase;
}

.ToggleDown {
  float: right;
  margin-top: 20px;
  margin-right: 20px;
}

.ToggleDown2{
  float: right;
  margin-top: 5px;
  margin-right: 20px;
}

.Nodes {
  width:280px;
  cursor: pointer;
}

.ForkliftText {
  white-space: nowrap;
  margin-top: 5px;
  vertical-align: top;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  color: #0068b0;
  width: 170px;
  overflow: hidden;
}

.ForkliftTextDisconnected {
  white-space: nowrap;
  margin-top: 5px;
  vertical-align: top;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  color: #797979;
  width: 170px;
  overflow: hidden;
}

.WorkerText {
  white-space: nowrap;
  margin-top: 10px;
  vertical-align: top;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  color: #505050;
  width: 230px;
  overflow: hidden;
}

.Refresh {
  display: inline-block;
  float: right;
  margin-right: 12px;
  margin-top: 11px;
  height: 14px;
  vertical-align: top;
  cursor: pointer;
}

.Unlink {
  display: inline-block;
  float:right;
  margin-top: 11px;
  margin-right: 3px;
  height: 14px;
  vertical-align: top;
  cursor: pointer;
  width: 18px;
}

.Battery {
  display: inline-block;
  margin-top: 13px;
  margin-right: 3px;
  width: 15px;
  height: 10px;
  vertical-align: top;
  float: right;
}

/*Conf modal */
.Confirm {
   position: absolute;
   top: 350px;
   left: 750px;
   width: 230px;
   height: 102px;
   box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.25);
   background-color: #ffffff;
   font-size: 14px;
   color: #404040;
   outline: none;
}

.Confirm .Text {
  text-transform: uppercase;
  float: right;
  margin-top: 25px;
  margin-right: 15px;
  color: #1b5faa;
  cursor: pointer;
}

.Last-Locate-Container {
  margin-left: 30px;
  margin-top: -5px;
}

.Last-Locate-Container .Asset {
  margin-top: 5px;
  font-family: Lato;
  font-size: 11px;
  line-height: 1.09;
  color: #132c4f;
}

.Last-Locate {
  display: inline-block;
  font-family: Lato;
  font-size: 11px;
  line-height: 1.09;
  color: #656565;
}

.Last-Locate-Time{
  display:inline-block;
  margin-left: 10px;
  font-family: Lato;
  font-size: 11px;
  line-height: 1.09;
  text-align: right;
  color: #132c4f;
}

.Disconnected {
  display: inline-block;
  font-family: Lato;
  font-size: 11px;
  line-height: 1.09;
  color: #ca0000;
}

.Color {
  float: right;
  margin-top: -50px;
  width: 4px;
  height: 50px;
}

.Line2 {
  margin-top: 5px;
  margin-bottom: 2px;
  margin-left: 10px;
  width: auto;
  height: 1.5px;
  background-color: #999999;
  /*border: solid 0.5px #999999;*/
}

.Line3 {
  margin-top: 5px;
  margin-left: 10px;
  width: auto;
  height: 1.5px;
  background-color: #999999;
  /*border: solid 0.5px #999999;*/
}

.Line4 {
  margin-top: 5px;
  margin-left: 10px;
  width: auto;
  height: 1.5px;
  background-color: #d3d3d3;
  /*border: solid .5px #d3d3d3;*/
}

.PillContainer {
  float: right;
  margin-top: 15px;
  margin-right: 20px;
}

.Pill {
  width: 100px;
  height: 20px;
  border-radius: 10px;
  background-color: #6c6c6c;
}

.PillText {
  z-index: 1;
  margin-top: -17px;
  margin-left: 30px;
  font-family: Lato;
  font-size: 12px;
  line-height: 1.25;
  color: #ffffff;
}

.IconSelectorContainer {
  margin-top: 12px;
}

.DateSelectorContainer {
  float: right;
  width: 700px;
}

.BackStyle {
  margin-top: 20px;
  color: #0068b0;
}

.DateText {
  display: inline-block;
  margin-top: 20px;
  color: #0068b0;
  margin-left: 10px;
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: center;
  color: #1b5faa;
  cursor: pointer;
}

.WeekText {
  display: block;
  margin-top: 3px;
  font-size: 14px;
  text-transform: uppercase;
}

.HourText {
  float: right;
  display: inline-block;
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 10px;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
  cursor: pointer;
}

.CalendarDropdown {
  z-index: 2;
  position: absolute;
  margin-top: 5px;
  margin-left: 400px;
  width: auto;
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
  cursor: pointer;
}

.xImg {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.Custom {
  margin-top: 15px;
  margin-left: 20px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}

.from {
  margin-top: 15px;
  margin-left: 20px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}

.calImage {
  display: inline-block;
  margin-top: 7px;
  margin-left: 20px;
  vertical-align: top;
}

.cal {
  display: inline-block;
  margin-top: 5px;
  margin-left: 3px;
  vertical-align: top;
}

.timeImage {
  width: 13px;
  height: 13px;
  display:inline-block;
  margin-top: 8px;
  margin-left: 15px;
  vertical-align: center;
}

.time {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 5px;
  height: 15px;
  width: 50px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #505050;
}

.okText {
  display: block;
  margin-top: 20px;
  float: right;
  margin-right: 25px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0068b0;
}


/*.loading {
  position: absolute;
  z-index: 1;
  margin-top: 0px;
  margin-left: 450px;
  font-family: Lato;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0068b0;
}
*/

.loading {
  position: absolute;
  z-index: 1;
  margin-top: 5rem;
  margin-left: 20rem;
  font-size: 1.5rem;
  color: #0894d8;
  width: 25rem;
  text-align: center;
}

/*heatmap legend
  width: 195px;
*/
.HeatmapLegendContainer {
  display: inline-block;
  vertical-align: top;
  width: 285px;
  height: 60px;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
  z-index: .5;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}

.LegendTitle {
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
}

.LegendIcon {
  float: right;
  margin-right:5px;
  margin-top: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.LegendIcon2 {
  float: right;
  margin-right:5px;
  margin-top: 5px;
  width: 24px;
  height: 16px;
  cursor: pointer;
}

.Legend {
  margin-top: 5px;
  width: 285px;
  height: 10px;
}

.Unit {
  font-size: 11px;
  display: block;
  position: absolute;
}

.EditCriteria {
  position: absolute;
  top: 300px;
  left: 700px;
  width: 330px;
  height: 150px;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
  outline: none;
}

.RackModalIcon {
  display: inline-block;
  width: 22px;
  height: 22px;
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

.CriteriaType {
  font-size: 14px;
  color: #006dc0;
}

.ok {
  display: block;
  margin-top: 10px;
  float: right;
  margin-right: 20px;
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0068b0;
  cursor: pointer;
}

.ShowHideLegend {
  width: 0.688rem;
  height: 3.8rem;
}

.ShowHideLegends {
  width: 0.688rem;
  height: 7.6rem;
}
