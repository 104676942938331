.mscsLoading {
  position: absolute;
  z-index: 1;
  margin-top: 10%;
  margin-left: 20%;
  width:50%;
  font-size: 2rem;
  color: #0894d8;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  /*grid-template-rows: 19% 19% 19% 19% 19%;(/)
  /*grid-template-columns: auto auto auto auto;*/
  grid-template-rows: auto auto auto auto auto;
  padding: 0rem  1rem 1rem 1rem;
  row-gap: .875rem;
  /*column-gap: 1.5rem;*/
  column-gap: 1%;
}

.grid-item {
  box-shadow: 4px 4px 10px 4px rgba(71, 84, 103, 0.08);
  background-color: #fff;
  text-align: left;
  padding:  1.375rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  color: #475467;
  overflow: auto;
}

.card1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
}

.card2 {
  grid-column: 3 / span 2;
  grid-row: 1 / span 1;
}

.card3 {
  grid-column: 3 / span 1;
  grid-row: 2 / span 2;
}

.card4 {
  grid-column: 4 / span 1;
  grid-row: 2 / span 2;
}

.card5 {
  grid-column: 1 / span 1;
  grid-row: 4 /span 2;
}

.card6 {
  grid-column: 2 / span 1;
  grid-row: 4 /span 2;
}

.card7 {
  grid-column: 3 / span 1;
  grid-row: 4/ span 2;
}

.card8 {
  grid-column: 4 / span 1;
  grid-row: 4 / span 2;
}

/* MscsLiveView */
.mscsLiveView .buttongroup {
  float: right;
}

.mscsLiveView button {
  width: auto;
  height: 1.688rem;
  margin: 0rem 0rem 0.688rem 1.25rem;
  font-size: .95rem;
  text-align: center;
  color: #444440;
  border: none;
  background: none;
}

.mscsLiveView .active {
  background: #e5f4ff;
  color: #1b5faa;
}

.mscsLiveView .mscsMap {
  margin-top: .938rem;
}

.mscsHeatmap .mscsMap {
  margin-top: 1.5rem;
}

.mscsTopRacks .mscsTable {
  margin-top: .688rem;
}

.mscsMetric .container {
  margin-left: 5%;
  font-weight: 500;
}

.mscsMetric .icon {
  width: 1.771rem;
  height: 1.563rem;
  vertical-align:top;
  margin-right: 2%;
}

.mscsMetric .icon2 {
  width: 2.875rem;
  margin-right: 2%;
  vertical-align: top;
}

.mscsMetric .field {
  margin-top: 2.688rem;
  font-size: 1.875rem;
}

.mscsMetric .value {
 margin-top: 0.625rem;
 font-size: 2rem;
}

.mscsBulletin {
  margin-top: 1%;
  text-align: center;
}

.mscsBulletin .container {
  margin: 2% 5% 1% 3%;
  text-align: left;
}

.mscsBulletin .subcontainer {
  display: inline-block;
  width: 50%;
}

.mscsBulletin .field {
  font-size: 1.875rem;
}

.mscsBulletin .value {
 margin-top: 0.625rem;
 font-size: 2.4375rem;
}

.mscsBulletin button {
    background: url('../img/icon-edit.png') no-repeat;
    cursor:pointer;
    border: none;
    margin-left:0.938rem;
    width: 1.563rem;
    height: 1.563rem;
    vertical-align: bottom;
}

.mscsBulletin input {
  width: 50%;
  height: 1.813rem;
}

.mscsChart .container {
  margin-top: 10%;
  width: 95%;
}
