/*******************************************************************************
 * LoginForm
 *
 * TODO: Define in a separate file?
 */

.login-page {
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, #0068b0), color-stop(40%, #f6f6f6));
  background: -webkit-linear-gradient(#0068b0 40%, #f6f6f6 40%);
  background: -o-linear-gradient(#0068b0 40%, #f6f6f6 40%);
  background: linear-gradient(#5575FB 40%, #f6f6f6 40%); /* dark blue and light grey */
  overflow: hidden;
}

.login-dialogue {
  background: white;
  width: 20em;
  margin: 0 auto;
  padding: 2em 3em;
  position: relative;
  margin-top: 8%;
  margin-bottom: 15%;
  border-radius: .5ex;
}

.login-dialogue .locix-image img{
  margin: 0 auto;
  padding: 20px 0;
  display: block;
}


.login-dialogue label {
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: rgba(0,0,0, .7);
}

.login-dialogue input[type="text"],
.login-dialogue input[type="password"],
.login-dialogue input[type="submit"] {
  display: block;
  width: 100%;
  margin: 5ex 0;
  border: none;
}

.login-dialogue ::-webkit-input-placeholder,
.login-dialogue ::-moz-placeholder,
.login-dialogue input:-moz-placeholder {
  text-transform: uppercase;
}

.login-dialogue input[type="text"],
.login-dialogue input[type="password"] {
  border-bottom: 1px solid #f6f6f6; /* light grey */
  font-size: 14px;
  font-weight: 300;
}

.login-dialogue a {
  cursor: pointer;
  float: right;
  font-size: 14px;
  color: #0068B0;
  margin-bottom: 30px;
}
.login-dialogue input[type="checkbox"]:hover {
  cursor: pointer;
}
.login-dialogue input[type="submit"] {
  color: white;
  height: 5.5ex;
  font-size: 14px;
  text-transform: uppercase;
  background: #5575FB; /* dark blue */
}
.login-form {
  text-align: center;
  /* transition: height 0.5s ease 0s; */
}
.error-message {
  color: red;
  content: '  ';
}
