.dashboard-menu {
  background-color: #5270ff; /*rgb(0, 104, 176);*/
  padding-top: 85px;
}

.menu-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-nav li {
  /* line-height: 0; */
  height: 50px;
  box-sizing: border-box;
  border-top: 1px solid rgb(0, 211, 252);
}
.menu-nav li:last-child {
  border-bottom: 1px solid rgb(0, 211, 252);
}
.menu-nav li a {
  display: block;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
/*menu-nav li a.active {
  border-right: 6px solid rgb(0, 211, 252);
}*/
a.home:link::after {
  content: url('../media/NavHome.svg');
}
a.home:hover::after {
  content: url('../media/NavHome_Hover.svg');
}
a.home-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/NavHome_Selected.svg');
}
/*a.home.active::after {
  content: url('../media/NavHome_Selected.svg');
}*/
a.camera:link::after {
  content: url('../media/NavCamera.svg');
}
a.camera:hover::after {
  content: url('../media/NavCamera_Hover.svg');
}
a.camera-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/NavCamera_Selected.svg');
}
/*a.camera.active::after {
  content: url('../media/NavCamera_Selected.svg');
}*/
a.constellation:link::after {
  content: url('../media/NavConstellation.svg');
}
a.constellation:hover::after {
  content: url('../media/NavConstellation_Hover.svg');
}
a.constellation-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/NavConstellation_Selected.svg');
}
/*a.constellation.active::after {
  content: url('../media/NavConstellation_Selected.svg');
}*/
a.asset-tracking:link::after {
  content: url('../media/NavAssetTracking.svg');
}
a.asset-tracking:hover::after {
  content: url('../media/NavAssetTracking_Hover.svg');
}
a.asset-tracking-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/NavAssetTracking_Selected.svg');
}
/*a.asset-tracking.active::after {
  content: url('../media/NavAssetTracking_Selected.svg');
}*/
a.settings-icon:link::after {
  content: url('../media/NavSettings.svg');
}
a.settings-icon:hover::after {
  content: url('../media/NavSettings_Hover.svg');
}
a.settings-icon-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/NavSettings_Selected.svg');
}
/*a.settings-icon.active::after {
  content: url('../media/NavSettings_Selected.svg');
}*/
a.smart-dock:link::after {
  content: url('../media/SmartDock_Unselected.svg');
}
a.smart-dock:hover::after {
  content: url('../media/SmartDock_Hover.svg');
}
a.smart-dock-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/SmartDock_Selected.svg');
}

a.wms:link::after {
  position: relative;
  top: 10px;
  left: 10px;
  content: url('../media/WMS_Default.svg');
}
a.wms:hover::after {
  content: url('../media/WMS_Hover.svg');
}
a.wms-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/WMS_Selected.svg');
}

/*customer/site/user management*/
a.management-icon:link::after {
  position: relative;
  top: 10px;
  left: 10px;
  content: url('../media/ManagementBtnOff.svg');
}

a.management-icon:hover::after {
  position: relative;
  top: 10px;
  left: 10px;
  content: url('../media/ManagementBtnHover.svg');
}
a.management-icon-selected {
  border-right: 6px solid rgb(0, 211, 252);
  content: url('../media/ManagementBtnOn.svg');
}
