.node-overlay {
  position: absolute;
  height: auto;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 35px;
  grid-template-rows: auto;
  grid-auto-flow: column;
}
.cameras-page .node-overlay {
  grid-template-columns: 1fr 45px;
}
.node-overlay:hover {
  height: 100%;
}
.node-overlay:hover .hover-caption {
  display: block;
}
.hover-caption {
  display: none;
}
.node-overlay .event-caption {
  font-size: 12px;
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  align-self: end;
  justify-self: start;
  font-weight:300;
  color: white;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 8px;
}
.node-overlay .node-event-buttons-image,
.node-overlay .node-event-buttons-video {
  /* grid-area: 2 / 3 / 0 / 1; */
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  align-self: end;
}

/*removed hover action from button display
display: none;*/
.node-overlay .overlay-btns {
  display: block;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  align-self: end;
  padding-right: 10px;
  padding-bottom: 15px;
}
.node-overlay:hover .overlay-btns {
  display: block;
}
.pic-btn {
  width: 25px;
  height: 25px;
  background-image: url('../media/CameraBTN.svg');
}
.pic-btn:hover {
  background-image: url('../media/CameraBTN_Hover.svg');
  cursor: pointer;
}
.vid-btn {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  background-image: url('../media/VideoBTN.svg');
}
.vid-btn:hover {
  background-image: url('../media/VideoBTN_Hover.svg');
  cursor: pointer;
}
.btn {
  background-size: contain;
}
.cameras-page .overlay-btns .btn {
  height: 32px;
  width: 32px;
}

.event-caption-video {
  font-size: 12px;
  color: white;
  /* position: absolute; */
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 8px;
}

.event-caption dl {
  margin: 0;
}

.event-caption dt {
	font-weight:bolder;
}

.event-caption dl dt {
  display: inline;
}

.event-caption dl dt:after {
  display: inline;
  content: ' : ';
}

.event-caption dl dd {
  display: inline;
  margin: 0;
}

.event-caption dl dd:after{
  display: block;
 content: ' ';
}

/* NodeEventFrame buttons */

.event-frame-button {
  /* position: absolute; */
  /* right: 0px; */
  width: 25px;
  height: 25px;
  /* right: 5px; */
}

.camera-summary-page .event-frame-button {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 5px;
  margin: 10px 0 10px 15px;
}

/* .camera-detail-page .event-frame .btn{
  position: absolute;
  width: 32px;
  height: 32px;
  right: 5px;
  margin: 10px 15px 10px 0px;
} */

.constellation-event-frame-button {
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 2;
    margin: 5px;
    display: inline-block;
    bottom: 0px !important;
}

.constellation-event-frame-button img {
    position: relative;
    right: 0px;
}

.edit-button-default-foreground,
.edit-button-hover-foreground {

}

/* .take-picture-button-default-foreground,
.take-picture-button-hover-foreground {
  bottom: 50px;
}

.take-video-button-default-foreground,
.take-video-button-hover-foreground {
  bottom: 10px;
} */
