.lapis-container {
  margin: 0% 10% 1% 10%;
  color: #475467;
  cursor: default;
}

.lapis-header {
  margin-left: 2%;
  font-size: 26px;
  display: inline-block;
  font-weight: lighter;
}

.lapis-update {
  display: inline-block;
  margin-left: 1%;
  font-size: 12px;
  color: #5b6b7c;
}

.lapis-type {
  display: inline-block;
  margin-left: 1%;
  color: #1b5faa;
}

.lapis-settings {
  float: right;
  margin-right: 3%;
}

.lapis-link {
  text-decoration: none;
  color: #1b5faa;
}

.lapis-container .grid-container {
  margin: 0% 0% 1% 0%;
  height: 90%;
  display: grid;
  grid-template-rows: 50% 50%;
  grid-template-columns: 100%;
  padding: 1rem  1rem 1rem 1rem;
}

.lapis-container .grid-item {
  box-shadow: 4px 4px 10px 4px rgba(71, 84, 103, 0.08);
  background-color: #ffffff;
  padding: 0rem  1rem 1rem 1rem;
  overflow: auto;
}

.lapis-container .card1 {
  grid-column: 1;
  grid-row: 1;
}

.lapis-container .card2 {
  grid-column: 1;
  grid-row: 2;
}

.lapis-table {
  outline: none;
  margin-top: 1%;
  text-align: left;
}

.lapis-tableheader {
  font-size: 13px;
  color: #ffffff;
  background-color: #516586;
}

.lapis-tablecell {
  font-size: 14px;
  text-align: left;
}

.lapis-search {
  margin-right: 5px;
}

.lapis-chart {
 margin-top: 10px;
 height:95%;
 width:100%;
 font-size: 14px;
}

.lapis-truck {
  font-size: 20px;
  color: #000000;
}

.lapis-container2 {
  margin: 0% 8% 0% 8%;
}

.lapis-container .grid-container2 {
  margin: 0% 0% 1% 0%;
  height: 90%;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  padding: 1rem  1rem 1rem 1rem;
}

.lapis-container .card3 {
  grid-column: 1;
  grid-row: 1;
  font-size: 1rem;
}

.lapis-message {
  margin: 15px 0px 0px 350px;
  color: #1b5faa;
}
