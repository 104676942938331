/*.ReactModal__Content--after-open,*/
.ReactModal__Content {
  font-family: "Lato", sans-serif;
  max-height: fit-content;
}
.ReactModal__Content form {
  height: auto;
  margin: 0 auto;
  text-align: center;
}
.ReactModal__Content form .form-group {
  margin-bottom: 25px;
}
.ReactModal__Content form .form-group > label {
  display:inline-flex;
  justify-content: space-between;
  width: 700px;
}
.ReactModal__Content form .form-group.small {
  width: 100px;
  margin: -15px auto 25px auto;
}
.ReactModal__Content form .form-group.small > label {
  width: 400px;
  font-size: 14px;
  color: rgba(0, 0, 0, .7);
}
.ReactModal__Content .Select {
  margin-bottom: 200px;
  outline: none;
}
.ReactModal__Content form input,
.ReactModal__Content form select {
  box-sizing: border-box;
  background-color: white;
  height: 25px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 275px;
  text-align: center;
  padding: 5px;
}

.ReactModal__Content form textarea {
  box-sizing: border-box;
  background-color: white;
  height: 150px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 400px;
  text-align: center;
  padding: 5px;
}

.ReactModal__Content form .input {
  width: 250px;
}

.ReactModal__Content .Select-control {
  width: 250px;
  /*padding-bottom: 5px;*/
}
.ReactModal__Content .Select-value,
.ReactModal__Content .Select-input {
  height: 25px;
}
.ReactModal__Content .Select-value {
  display: flex;
  align-content: stretch;
  width: 200px;
  border: none;
  color: inherit;
  background-color: #f3f3f3;
  vertical-align: middle;
}
.ReactModal__Content .Select-input {
  margin-top: 5px;
  font-size: 11px;
  width: 200px;
}
.ReactModal__Content .Select input {
  width: 100%;
  border: none;
  padding: 0;
}
.ReactModal__Content .Select .Select-value-label {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactModal__Content .Select-value-icon {
  order: 1;
  flex-shrink: 0;
  margin-top: 2.5px;
  margin-right: 2.5px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  font-weight: 100;
  font-size: 14px;
  padding: 0;
  border-radius: 20px;
  border: none;
  background-color: #9e9e9e;
  color: white;
}
.ReactModal__Content .Select-value-icon:hover {
  background-color: #c51622;
  /*color: #c51622;*/
  color: white;
}

.ReactModal__Content form .button-group {
  text-align: right;
}
.ReactModal__Content form .button-group.left {
  text-align: left;
}
.ReactModal__Content form button {
  color: white;
  background-color: #6e8fe2;
  border: none;
  outline: none;
  border-radius: 2px;
  padding: 5px 40px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 200;
  margin-left: 20px;
}
.ReactModal__Content form button:hover {
  cursor: pointer;
}
.ReactModal__Content form button.cancel-button {
  background-color: transparent;
  border: none;
  color: inherit;
}
.ReactModal__Content form button.left {
  float: left;
}
.ReactModal__Content .delete-button {
  background-color: #c51622;
}

.ReactModal__Content .rangeslider {
  width: 200px;
  margin: 10px 0;
  box-shadow: none;
  height: 0.5rem;
  border: 1px solid #ccc;
  background: none;
}
.ReactModal__Content .rangeslider .rangeslider__handle {
  border-radius: 1rem;
  border: none;
  box-shadow: none;
  background: #6e8fe2;
  width: 1.5rem;
  height: 1.5rem;
}
.ReactModal__Content .rangeslider .rangeslider__handle::after {
  content: none;
}
.ReactModal__Content .rangeslider .rangeslider__fill {
  background: inherit;
  box-shadow: none;
}
