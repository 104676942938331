.constellation-heading .constellation-btns .btn {
  /* display: inline-block; */
  margin-top: 0;
}
.constellation-heading .constellation-btns {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.constellation-heading {
  display: flex;
  align-items: center;
}
.constellation-btn {
  margin-left: 20px;
}
.constellation-heading a {
  color: #5295e4;
  text-decoration: none;
}
