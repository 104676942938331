/* vim: set fileencoding=utf-8 */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400');

html {
  /* width: fit-content; */
}
html,
body,
#root {
  height: 100%;
  margin: 0;
}

.App {
  background: #f7f7f7;
}
.dashboard-page {
  width: inherit;
  background: #f7f7f7;
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-template-rows: 85px 1fr;
  grid-template-areas:
    "sidebar header"
    "sidebar main";
  min-height: 800px;
  justify-content: stretch;
}

.header {
  grid-area: header;
}
.dashboard-menu {
  grid-area: sidebar;
}
.content-row {
  grid-area: main;
}

.fixedDataTableLayout_main {
  z-index: 0;
}
