/*2dReactChart.css - for LPS charts */

/*height: 250px;*/
.SiteChart {
  margin-top: 15px;
  margin-left: 10px;
  width: 1055px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.SiteHeader {
  position: relative;
  top: 15px;
  margin-left: 15px;
  opacity: 0.8;
  font-family: Lato;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.SiteHeader img {
  margin-left: 5px;
  vertical-align: bottom;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.SiteAnalyticsContainer {
  vertical-align: top;
  display: inline-block;
  margin-left: 18px;
  margin-top: 30px;
  width: 780px;
  height: auto;
}

.SiteAnalyticsBox {
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 230px;
  height: 79px;
  border: solid 1px #505050;
  background-color: #ffffff;
}

/* Summary chart */
.SummaryChart {
  margin-top: 15px;
  margin-left: 10px;
  width: 1055px;
  height: 246px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.SummaryAnalyticsBox {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  width: 185px;
  height: 175px;
  border: solid 1px #505050;
  background-color: #ffffff;
}

/*border: solid 1px #505050;*/
.SitePieChartContainer {
  display: inline-block;
  margin-top: 20px;
  margin-left: 15px;
  width: 185px;
  height: 185px;
  background-color: #ffffff;
}

.PieChartDetail {
  position: relative;
  top: -120px;
  left: 45px;
  width: 95px;
  height: 80px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #505050;
  z-index: .5;
}

/*image*/
.SiteDistance {
  display: inline-block;
  width: auto;
  height: 43px;
  margin-top: 20px;
  margin-left: 18px;
}

.StatsContainer {
  display: inline-block;
  vertical-align: top;
  float: right;
  margin-right: 15px;
  margin-top: 15px;
}

.Stats {
  display: inline-block;
  vertical-align: top;
  float: right;
  font-family: Lato;
  font-size: 19px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #505050;
}

.StatsLabel {
  display: block;
  margin-top: 35px;
  text-transform: uppercase;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: right;
  color: #505050;
}

.SummaryLabel {
  display: block;
  margin-top: 35px;
  text-transform: uppercase;
  font-family: Lato;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #505050;
}

.SummaryContainer {
  margin-top: 10px;
  margin-left: 20px;
  width: 150px;
}

.SummaryPropertyContainer {
  display: inline-block;
  width: 80px;
  height: 90px;
  text-transform: uppercase;
}

.SummaryValueContainer {
  display: inline-block;
  float: right;
  width: 65px;
  height: 90px;
}

.SummaryProperty {
  font-family: Lato;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: right;
  color: #505050;
  margin-bottom: 11px;
}

.SummaryValue {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: right;
  color: #505050;
  margin-bottom: 9px;
}

.ExpandIcon {
  cursor: pointer;
  display:block;
  float:right;
  vertical-align: top;
  width: 20px;
  height: 20px;
}

.LegendContainer {
  margin-left: 85px;
  margin-top: 40px;
  height: 190px;
  width: 890px;
}

.LegendMap {
  display: inline-block;
  vertical-align: top;
  width: 280px;
  height: 190px;
}

.LegendLabelContainer {
  display: inline-block;
  width: 610px;
  height: 190px;
}

/*background-color: #ff7c43;*/
.LegendKey {
  display: inline-block;
  margin-left: 25px;
  margin-top: 20px;
  width: 20px;
  height: 20px;
}

.LegendValue {
  display: inline-block;
  margin-left: 10px;
  margin-top: 20px;
  width: 20px;
  vertical-align: top;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #505050;
}

/*background-color: red;*/
.SiteChartContainer {
  vertical-align: top;
  display: inline-block;
  margin-left: 18px;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 960px;
  height: auto;
  /*background-color: red;*/
}

.ActivityLegend {
  width: 12px;
  height: 12px;
  float:right;
  margin-top: 7px;
  margin-right: 10px;
  background-color: #b3f66d;
}

.ActivityText {
  float: right;
  margin-top: 5px;
  margin-right: 15px;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #606060;
}

.ChartOptionsImg{
  float: right;
  width: 30px;
  height: 26px;
  cursor: pointer;
}

.LegendOptionsImg {
  float: right;
  width: 13px;
  height: 13px;
  margin-right: 15px;
  margin-top: 6px;
  cursor: default;
}

.LegendOptionsText {
  margin-right: 7px;
  margin-top: 5px;
  float: right;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1b5faa;
  cursor: default;
}

.MetricTable {
  outline: none;
  margin-top: 20px;
  width: auto;
  height: auto;
  overflow: hidden;
}

.MetricHeader {
  font-family: Lato;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #ffffff;
  text-align: center;
  background-color: #1b5faa;
}

.MetricCell {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #0068b0;
  text-align: center;
}

.MetricCell2 {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #444440;
  text-align: center;
  background-color: rgba(137, 137, 137, 0.1);
}

.MetricCell3 {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #444440;
  text-align: center;
  background-color: rgba(0, 145, 255, 0.1);
}

.ColumnGroup1{
  background-color: white;
}

.ColumnGroup2{
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #444440;
  text-align: center;
  background-color: rgba(137, 137, 137, 0.1);
}

.ColumnGroup3{
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #444440;
  text-align: center;
  background-color: rgba(0, 145, 255, 0.1);
}

/*upload modal*/
.Upload {
   position: absolute;
   top: 350px;
   left: 825px;
   /*position: absolute;
   top: 30%;
   left: 35%;
   transform: translate(-50%, -50%);*/
   width: 340px;
   height: 180px;
   transform: translate(-50%, -50%);
   background-color: #ffffff;
   font-family: Lato;
   font-size: 16px;
   color: #505050;
   border: solid 1px #1b5faa;
   outline: none;
}

.UploadContainer {
  margin-top: 15px;
  margin-left: 15px;
}

.SelectFileText {
  font-size: 14px;
  margin-top: 15px;
}

.DownloadButton {
  display: inline-block;
  cursor: pointer;
  margin-top: 50px;
  margin-left: 225px;
  padding-top: 5px;
  width: 85px;
  height: 20px;
  border: solid 1px #1b5faa;
  background-color: #ffffff;
  font-family: Lato;
  font-size: 12px;
  color: #1b5faa;
  text-align: center;
}

.UploadText {
  display: inline-block;
  margin-left: 3px;
  vertical-align: top;
}

.LoadingStatus {
  display: inline-block;
  position: relative;
  top: -30px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0068b0;
  width: auto;
}

.ChartDownloadModal {
  position: absolute;
  top: 350px;
  left: 700px;
  width: 340px;
  height: 175px;
  background-color: white;
  font-family: Lato;
  font-size: 16px;
  color: #505050;
  border: solid 1px #1b5faa;
  outline: none;
}

.UploadContainer .FileType {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.UploadContainer .FileType img{
  width: 24px;
  height: 30px;
}

.UploadContainer .SelectType {
  display: inline-block;
  vertical-align: top;
  margin: 17px 20px 0px 10px;
}
