.constellation-detail-page .activity-chart {
  /* width: 1000px; */
}
.constellation-detail-page {
  min-width: 1012px;
}
.constellation-detail-page .heading .event-frame {
  width: 316px;
  height: 178px;
}
.constellation-detail-page .recent-events {
  margin-top: 15px;
  background: white;
  -webkit-box-shadow: 1px 1px 1px 1px rgb(225,225,225);
          box-shadow: 1px 1px 1px 1px rgb(225,225,225);
}
.constellation-detail-page .recent-events .event-frame .overlay-btns {
  display: none;
}
