.CalendarDropdownCommon {
  z-index: 2;
  position: absolute;
  margin-top: 5px;
  /*margin-left: 140px;*/
  width: 330px;
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
  font-family: Lato;
  color: #505050;
}

.CalendarDropdownCommon .xImg {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.CalendarDropdownCommon .Custom {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 16px;
}

.CalendarDropdownCommon .from {
  margin-top: 20px;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 300;
}

.CalendarDropdownCommon .calImage {
  display: inline-block;
  margin-top: 15px;
  margin-left: 20px;
  vertical-align: top;
  width: 20px;
  height: 20px;
}

.CalendarDropdownCommon .cal {
  display: inline-block;
  margin-top: 8px;
  margin-left: 10px;
  vertical-align: top;
}

.CalendarDropdownCommon .timeImage {
  width: 20px;
  height: 20px;
  display:inline-block;
  margin-top: 15px;
  margin-left: 15px;
  vertical-align: center;
}

.CalendarDropdownCommon .timeHour {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 10px;
  height: 28px;
  width: 35px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.CalendarDropdownCommon .timeMin {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 10px;
  height: 28px;
  width: 35px;
  border: solid 0.5px #979797;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.CalendarDropdownCommon .upArrow {
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  cursor: pointer;
}

.CalendarDropdownCommon .upArrow img{
  width: 16px;
  height: 16px;
}

.CalendarDropdownCommon .upArrow img:hover{
  content: url('./img/up_hover.png');
}

.CalendarDropdownCommon .upArrow img:active {
  content: url('./img/up_active.png');
}

.CalendarDropdownCommon .downArrow {
  display: inline-block;
  vertical-align: bottom;
  margin-top: 25px;
  margin-left: -16px;
  cursor: pointer;
}

.CalendarDropdownCommon .downArrow img {
  width: 16px;
  height: 16px;
}

.CalendarDropdownCommon .downArrow img:hover {
  content: url('./img/down_hover.png');
}

.CalendarDropdownCommon .downArrow img:active {
  content: url('./img/down_active.png');
}

.CalendarDropdownCommon .colon {
  vertical-align: top;
  margin-top: 12px;
  margin-left: 5px;
  display: inline-block;
  font-size: 18px;
  font-weight: 1000;
  color: #505050;
}

.CalendarDropdownCommon .errorText {
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  margin-left: 20px;
  color: red;
}

.CalendarDropdownCommon .applyText {
  display: inline-block;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 16px;
  float: right;
  margin-right: 20px;
  color: #0068b0;
  cursor: pointer;
}

.react-datepicker__input-container input {
  width: 85px;
  height: 30px;
  font-family: Lato;
  font-size: 14px;
  text-align: center;
  color: #505050;
}

.sortIconStyle {
  margin-left: 5px;
}

.common .searchIconStyle {
  position: relative;
  left: -310px;
}

.common .calendarIconStyle {
  width: 16px;
  height: 16px;
  vertical-align: top;
  margin-top: 8px;
}

.common .cal {
  z-index: 1;
  position: absolute;
  left: 290px;
  top: 140px;
}

/* daterangetime */
.common .daterangetime {
  margin: 10px 0px 0px -100px;
  z-index: 1;
  position: absolute;
  width: 510px;
  height: 275px;
  padding: 30px 22px 20px 20px;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
}

.common .daterangetime .timeform {
  cursor: pointer;
  display: inline-block;
  margin-left: 5%;
  width: 45%;
  height: 100%;
  vertical-align: top;
  font-family: Lato;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.14;
}

.common .daterangetime .checkbox {
  cursor: pointer;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0.063rem 0.313rem 0.063rem 0;
  border: solid 0.5px #505050;
  vertical-align: top;
}

.common .daterangetime .daysofweek {
  display: inline-block;
  margin: 1.438rem 5.5rem 0.625rem 0;
  font-size: 0.625rem;
  color: #505050;
  text-transform: uppercase;
}

.common .daterangetime .daycontainer {
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
}

.common .daterangetime .daybutton {
  width: 1.375rem;
  height: 1.125rem;
}

.common .daterangetime .alldaybutton {
  float: right;
  width: 3.75rem;
  height: 1.25rem;
  margin-top: 1.125rem;
}

.common .daterangetime .fromto {
  display: inline-block;
  margin: .8rem 5.5rem 0.625rem 0;
  font-size: 0.625rem;
  text-transform: uppercase;
}

.common .daterangetime input {
  height: 1.65rem;
  width: 1.615rem;
  border: solid 0.5px #979797;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.common .daterangetime .uparrow {
  width: 1.063rem;
  height: 1rem;
  vertical-align: top;
}

.common .daterangetime .downarrow {
  width: 1.063rem;
  height: 1rem;
  margin-left: -1.063rem;
  vertical-align: bottom;
}

.common .daterangetime .colon {
  display: inline-block;
  width: 0.188rem;
  height: 0.625rem;
  margin:0 0.2rem 0 0.2rem;
}

.common .daterangetime .applycontainer {
  position: absolute;
  bottom: 30px;
  right: 30px;
  font-weight: 1000;
}

.common .daterangetime .error {
  color: red;
  margin: 0px 0px 5px 15px;
}

.common .daterangetime .cancel {
  margin-left: 25px;
  float: right;
  color: #606060;
}

.common .daterangetime .apply {
  color: #1b5faa;
}

/*Warn modal */
.Warn {
   position: absolute;
   top: 300px;
   left: 500px;
   width: 30rem;
   height: 13.875rem;
   box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
   background-color: #ffffff;
   font-size: 16px;
   color: #404040;
   outline: none;
}

.WarnContainer {
  margin: 30px 30px 30px 30px;
}

.WarnContainer h3 {
  font-size: 1.375rem;
  text-transform: uppercase;
}

.WarnContainer .cancel {
  position: absolute;
  left: 56px;
  bottom: 30px;
  width: 8.125rem;
  height: 2.375rem;
  border-radius: 2px;
  border: solid 0.5px rgba(0, 0, 0, 0.5);
}

.WarnContainer .continue {
  position: absolute;
  left: 275px;
  bottom: 30px;
  width: 8.125rem;
  height: 2.375rem;
  border-radius: 2px;
  background-color: #1b5faa;
  color: #ffffff;
}

/*help modal*/
.Help {
   position: absolute;
   top: 150px;
   left: 250px;
   width: auto;
   height: auto;
   min-width: 30rem;
   min-height: 13.875rem;
   box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
   outline: none;
   cursor: default;
   background-color: white;
}

.HelpContainer {
  margin: 20px 20px 20px 20px;
}

.HelpContainer .HelpContent {
  font-size: 18px;
}

.settings .edit .help button {
  background: url('../../media/help.png') left top no-repeat; background-size: 20px 20px;
  width: 20px;
  height: 20px;
  border: 0;
  float: right;
}

.settings .edit .help button:hover {
  background: url('../../media/help.png') left top no-repeat; background-size: 20px 20px;
}

.settings .edit .help button:active {
  background: url('../../media/help.png') left top no-repeat; background-size: 20px 20px;
}

.settings .edit .locix-select select{
  width: auto;
  padding: 3px 3px 3px 3px;
  background-color: white;
  color: #666666;
  border: solid 0.5px #979797;
}
