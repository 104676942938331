/* SmartDockPage - Dock Icons View */
.DocksContainer {
  margin-left: 10px;
  width: 1360px;
  min-height: 50px;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
  white-space: nowrap;
  font-family: Lato;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #505050;
}

.DockHeader {
  display: inline-block;
  margin: 20px 0px 0px 15px;
  font-size: 22px;
}

.DockViewOption {
  display: inline-block;
  font-size: 14px;
  margin: 25px 15px 0 0;
  float: right;
  cursor: pointer;
}

.SelectedOption {
  color: #1b5faa;
}

.IconContainer {
  overflow-y: auto;
}

.Dock {
  display: inline-block;
  margin: 15px 15px 15px 15px;
  vertical-align: top;
  text-align: center;
}

.DockId {
  font-size: 16px;
}

.DockDwell {
  margin-top: 5px;
  font-size: 14px;
  color: #393939;
  font-style: italic;
}

.DockImg {
  margin-top: 6px;
  width: 60px;
  height: 64px;
  cursor: pointer;
}

/******* Camera View *********/
/******* Camera View *********/
/******* Camera View *********/
.CameraContainer {
  margin: 20px 20px 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.DockCameraImg {
  margin: -10px 15px 0px 0px;
}

.DockCameraImg img{
  width: 205px;
  height: 135px;
}

.DockCameraHeader {
  position: relative;
  top: 20px;
  width: 195px;
  height: 15px;
  padding: 3px 5px 3px 5px;
  background-color: rgba(40, 40, 40, 0.75);
  font-family: Lato;
  font-size: 15px;
  color: #ffffff;
}

.DockCameraHeader img{
  margin-left: 5px;
  width: 12px;
  height: 12px;
  background-color: #ffffff;
}

.DockAlarmIcon {
  float: right;
  margin-right: 5px;
  font-size: 13px;
  color:#c85000;
}

.DockCameraFooter {
  position: relative;
  top: -20px;
  width: 195px;
  height: 15px;
  padding: 3px 5px 3px 5px;
  background-color: rgba(40, 40, 40, 0.75);
  font-family: Lato;
  font-size: 13px;
  color: #ffffff;
}

.DockCameraFooter img{
  margin-left: 5px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.DockCameraFooter img:hover {
  content: url('../img/refresh-hover.png');
}

.DockCameraFooter img:active {
  content: url('../img/refresh-pressed.png');
}

.OccupiedTime {
  font-size: 13px;
  float:right;
  vertical-align: bottom;
}

/******* Camera View *********/
/******* Camera View *********/
/******* Camera View *********/

.Triangle {
  display: block;
  margin-top: 10px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #FFFFFF;
}

/* SmartDock Detail Page */
.DockDetailContainer {
  display: block;
  width: 1315px;
  height: 360px;
  margin-left: 30px;
  background-color: #ffffff;
}

.DockAlertIcon {
  display: inline-block;
  margin: 0px -10px 0px 20px;
  color: #c85000;
  font-size: 20px;
}

.DockDetailHeader {
  display: inline-block;
  text-decoration: none;
  margin: 30px 0px 0px 20px;
  font-family: Lato;
  font-size: 22px;
  color: #505050;
  text-transform: uppercase;
}

.DockDetailRefresh {
  display: inline-block;
  margin: 28px 0px 0px 10px;
  width: 25px;
  height: 25px;
  vertical-align: bottom;
  cursor: pointer;
}

.DockDetailRefresh:hover {
  content: url('../img/refresh-hover.png');
}

.DockDetailRefresh:active {
  content: url('../img/refresh-pressed.png');
}

.DockDetailClose {
  float: right;
  margin: 20px 20px 0px 0px;
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}

.ExtCamera {
  display: inline-block;
  margin-top: 20px;
  margin-left: 20px;
}

.ExtCamera img {
  width: 480px;
  height: 270px;
}

.ExtName {
  position:absolute;
  margin: -65px 0px 0px 20px;
  width: 465px;
  height: 55px;
  background-color: rgba(23, 23, 25, 0.5);
  font-family: Lato;
  font-size: 20px;
  color: #ffffff;
  padding: 5px 0px 0px 15px;
}

.ExtDate {
  font-size: 15px;
}

.IntName {
  margin-left: 520px;
}

.ImgHeader {
  position: absolute;
  margin-left: 20px;
  margin-top: -270px;
  width: 475px;
  height: 20px;
  opacity: 0.8;
  background-color: #b7b7b7;
  font-family: Lato;
  font-size: 16px;
  color: #303030;
  padding: 5px 0px 5px 5px;
}

.BatteryIcon {
  position: absolute;
  margin-left: 475px;
  margin-top: -267px;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.DetailContainer {
  vertical-align: top;
  display: inline-block;
  margin: 20px 0px 0px 20px;
  width: 265px;
  height: 270px;
  font-family: Lato;
  font-size: 18px;
}

.DockStatusImg {
  width: 100px;
  height: 30px;
}

.DockDetail {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.DockDetailAttr {
  float: right;
  text-align: right;
}

.GapAlarmIcon {
  margin-right: 5px;
}

.DockDetailCard {
  margin: 20px 0px 0px 10px;
  padding-bottom: 20px;
  width: 1360px;
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
}

.Back {
  width: 28px;
  height: 18px;
  margin-left: 20px;
  margin-right: -10px;
}

/* DockDetailPage */
.SmartDockPage {
  width: 1360px;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  background-color: #ffffff;
  margin: 10px 0px 20px 10px;
  padding-bottom: 10px;
  font-family: Lato;
  color: #505050;
}

.History {
  display: inline-block;
  margin: 15px 0px 0px 15px;
  font-family: Lato;
  font-size: 22px;
}

.DockDateContainer {
  display: inline-block;
  margin-left: 30%;
  color: #0068b0;
  cursor: pointer;
  width: 60%;
}

.DockDate {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  margin-left: 20px;
}

.Left {
  margin-right: 20px;
}

.Right {
  margin-left: 20px;
}

.CannedSelect {
  float: right;
}

.Days {
  /*float: right;*/
  display: inline-block;
  margin: 5px 20px 0px 0px;
  font-size: 14px;
  color: #505050;
}

.SnapShotContainer {
  display: inline-block;
  margin: 15px 0px 15px 15px;
  font-family: Lato;
  font-size: 19px;
  color: #505050;
}

.DockSnapShot {
  display:inline-block;
  width: 245px;
  height: auto;
  border: solid 1px #505050;
  margin-right: 20px;
}

.DockIcon {
  margin: 15px 0px 0px 15px;
  width: 40px;
  height: 40px;
}

.TruckIcon {
  width: 85px;
  height: 40px;
}

.ClockIcon {
  width: 38px;
  height: 38px;
}

.DockText {
  display: inline-block;
  margin: 15px 15px 0px 0px;
  float: right;
  font-weight: 300;
}

.DockStatus {
  margin: 0px 15px 15px 0px;
  font-size: 14px;
  text-align: right;
  text-transform: uppercase;
}

.DockDelay {
  border: solid 1px #c85000;
  color: #c85000;
}

.DockChartContainer {
  margin: 0px 0px 0px 15px;
  height: 270px;
  width: 1330px;
}

/************** Table ***********/
.StatusTable {
  margin: 30px 15px 15px 15px;
  font-family: Lato;
  text-align: center;
  vertical-align: center;
  color: #444440;
  outline: none;
}

.StatusTableHeader {
  background: #0068b0;
  font-size: 15px;
  color: #ffffff;
  text-transform: uppercase;
}

.StatusTableCell {
  font-size: 16px;
  text-transform: capitalize;
}

.AlarmIcon {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  color: #c85000;
}

.Summary {
  width: 75px;
  height: 15px;
  margin-left: 30px;
  font-size: 15px;
  color: #ffffff;
  padding: 5px 10px 7px 10px;
  background-color:  #1b5faa;
  cursor: pointer;
}

.SummaryDeactivated {
  background-color: #b7b7b7;
}

/***********************************/
/***********************************/
/***********************************/
/* smart dock page - will deprecate */
.DockTableContainer {
  margin: 15px 15px 15px 15px;
  font-family: Lato;
}

.DockSearch {
  height: 18px;
  width: 240px;
  border-radius: 2px;
  border: solid 1px  #505050;
  font-family: Lato;
  font-size: 15px;
  color: #404040;
  padding: 8px 8px 8px 30px;
  outline: none;
  cursor: pointer;
}

.SearchIcon {
  position: absolute;
  margin-top: 10px;
  margin-left: -270px;
  color: #1b5faa;
  width: 12px;
  height: 22px;
}

.DropDown {
  position: relative;
  left: 20px;
  width: 190px;
  height: 18px;
  border-radius: 2px;
  border: solid 0.5px #505050;
  font-family: Lato;
  font-size: 15px;
  color: #404040;
  padding: 8px 0px 8px 10px;
  outline: none;
  cursor: pointer;
}

.DropdownIcon {
  position: absolute;
  margin-top: 10px;
  margin-left: -10px;
  color: #1b5faa;
  width: 12px;
  height: 22px;
}

.DropDownMenu {
  display: none;
  position: absolute;
  left: 390px;
  width: 180px;
  height: 120px;
  border-radius: 5px;
  border: solid 1px #686868;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 300;
  color: #575757;
  z-index: 1;
  padding: 6px 0px 6px 12px;
}

.MenuItem {
  display: block;
  padding-top: 6px;
  padding-left: 3px;
  padding-bottom: 6px;
  text-align: left;
  cursor: pointer;
}

.MenuItem:hover {
  font-weight: 500;
  color: #0b65a5;
}

.Last24 {
  display: inline-block;
  float: right;
  font-size: 16px;
  color: #505050;
  margin-top: 15px;
  margin-right: 25px;
}

.DocksTable {
  margin-top: 15px;
  font-family: Lato;
  text-align: center;
  vertical-align: center;
  color: #444440;
  outline: none;
}

.SortIcon {
  margin-left: 10px;
  color: #ffffff;
}

.DockName {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: #0068b0;
}

.DockBattery {
  display: inline-block;
  margin-left: 10px;
  width: 24px;
  height: 12px;
}

.Status {
  width: 62px;
  height: 10px;
  border-radius: 2px;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 7px 10px 10px 10px;
  text-align: center;
  margin-left: 25px;
}

.Occupied {
  border: solid 1px #606060;
  background-color: #606060;
}

.Available {
  border: solid 1px #1d8901;
  background-color: #1d8901;
}

.Offline {
  border: solid 1px #b7b7b7;
  background-color: #b7b7b7;
}


/**** Dock Interval ***/
.DockInterval {
  background-color: #ffffff;
  margin: 10px 0 0 10px;
  width: 1360px;
  height: 55px;
  box-shadow: 0 2px 8px 0 rgba(174, 174, 174, 0.5);
  font-family: Lato;
  border-bottom: 1px solid #1b5faa;
}

.DockIntervalContainer {
  display: inline-block;
  margin-top: 15px;
  margin-left: 15px;
  color: #1b5faa;
  cursor: pointer;
  width: 100%;
}

.DownloadIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.DockDownload {
   margin: 270px 0px 0px 540px;
   width: 340px;
   height: 175px;
   background-color: #ffffff;
   font-family: Lato;
   font-size: 16px;
   color: #505050;
   border: solid 1px #1b5faa;
   outline: none;
}

.DockIntervalDateContainer {
  display: inline-block;
  margin-left: 38%;
  vertical-align: top;
  width: 58%;
}

/***** SummaryDetail ***/
.PlayButton {
  margin-left: 5px;
  color: blue;
  font-size: 20px;
}

.SummarySlide {
  display: inline-block;
  margin: 20px 0px 40px 20px;
  height: 270px;
  width: 480px;
  overflow: hidden;
}

.SummaryCameraContainer {
  height: 270px;
  /*transition: 0.2s;*/
  /*transition: 'transform ease-out 0.45s'*/
}

.SummaryCameraContainer img {
  width: 480px;
}

.SummaryCameraName {
  margin: -65px 0px 0px 0px;
}

/* Range Slider */
.RangeSliderContainer {
  position: absolute;
  margin: 140px 0px 0px 40px;
  width: 1290px;
  height: 0px;
  /*background: red;*/
}

.DockDetailRangeContainer {
  margin: 140px 0px 0px 30px;
  width: 1315px;
}

.RangeSlider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 0px; /* Specified height */
  background: white;
  outline: none; /* Remove outline */
}

.RangeSlider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 4px; /* Set a specific slider handle width */
  height: 250px; /* Slider handle height */
  background: #1b5faa;
  cursor: pointer; /* Cursor on hover */
  border-radius: 25px;
}
